import { getCookie } from "@/utils/token";
import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    path: "/:catchAll(.*)",
    name: "Error",
    hidden: true,
    component: () => import("@/components/error.vue"),
  },
  {
    path: "/",
    name: "首页",
    redirect: "/login",
    hidden: true,
    component: () => import("@/pages/login/index.vue"),
  },
  {
    path: "/login",
    name: "登录",
    hidden: true,
    component: () => import("@/pages/login/index.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "carbon:home",
    redirect: "/dashboard/home",
    component: () => import("@/pages/container"),
    children: [
      {
        path: "/dashboard/home",
        name: "主页",
        icon: "bx:home",
        component: () => import("@/pages/dashboard/index.vue"),
      },
    ],
  },
  {
    path: "/sprite",
    name: "精灵管理",
    icon: "ci:image-alt",
    component: () => import("@/pages/container"),
    children: [
      {
        path: "/sprite/category",
        name: "精灵分类",
        icon: "carbon:category",
        component: () => import("@/pages/sprite/category.vue"),
      },
      {
        path: "/sprite/list",
        icon: "lucide:layout-list",
        name: "精灵列表",
        component: () => import("@/pages/sprite/list.vue"),
      },
    ],
  },
  {
    path: "/video",
    name: "背景管理",
    redirect: "/video/category",
    icon: "carbon:image-copy",
    component: () => import("@/pages/container"),
    children: [
      {
        path: "/video/category",
        name: "背景分类",
        icon: "icon-park-outline:category-management",
        component: () => import("@/pages/video/category.vue"),
      },
      {
        path: "/video/list",
        name: "背景列表",
        icon: "charm:layout-list",
        component: () => import("@/pages/video/list.vue"),
      },
    ],
  },
  {
    path: "/sound",
    name: "声音管理",
    icon: "fluent:headphones-sound-wave-32-regular",
    component: () => import("@/pages/container"),
    children: [
      {
        path: "/sound/category",
        name: "声音分类",
        icon: "entypo:sound-mix",
        component: () => import("@/pages/sound/category.vue"),
      },
      {
        path: "/sound/list",
        name: "声音列表",
        icon: "entypo:sound",
        component: () => import("@/pages/sound/list.vue"),
      },
    ],
  },
  {
    path: "/user",
    name: "用户管理",
    icon: "carbon:user-multiple",
    component: () => import("@/pages/container"),
    children: [
      {
        path: "/user/user",
        name: "用户列表",
        icon: "heroicons-outline:user-group",
        component: () => import("@/pages/user/user.vue"),
      },
      {
        path: "/user/sprite",
        name: "用户精灵",
        icon: "maki:hot-spring",
        component: () => import("@/pages/user/sprite.vue"),
      },
      {
        path: "/user/project",
        name: "工程列表",
        icon: "ant-design:fund-projection-screen-outlined",
        component: () => import("@/pages/user/project.vue"),
      },
      {
        path: "/user/feedback",
        name: "意见反馈",
        icon: "codicon:feedback",
        component: () => import("@/pages/user/feedback.vue"),
      },
      {
        path: "/user/session",
        name: "登录日志",
        icon: "ant-design:logout-outlined",
        component: () => import("@/pages/user/session.vue"),
      },
    ],
  },
  {
    path: "/product",
    name: "产品管理",
    icon: "carbon:carbon-for-ibm-product",
    component: () => import("@/pages/container"),
    children: [
      {
        path: "/product/list",
        name: "产品列表",
        icon: "gridicons:product-external",
        component: () => import("@/pages/user/user.vue"),
      },
    ],
  },
  {
    path: "/pay",
    name: "支付管理",
    icon: "icon-park-outline:pay-code-one",
    component: () => import("@/pages/container"),
    children: [
      {
        path: "/pay/order",
        name: "订单列表",
        icon: "icon-park-outline:pay-code-two",
        component: () => import("@/pages/pay/order.vue"),
      },
      {
        path: "/pay/pay",
        name: "支付列表",
        icon: "ri:wechat-pay-line",
        component: () => import("@/pages/pay/pay.vue"),
      },
    ],
  },
  {
    path: "/stat",
    name: "数据统计",
    icon: "carbon:data-vis-1",
    component: () => import("@/pages/container"),
    children: [
      {
        path: "/stat/user",
        name: "平台数据",
        icon: "clarity:data-cluster-outline-badged",
        component: () => import("@/pages/stat/home.vue"),
      },
      {
        path: "/stat/device",
        name: "设备信息",
        icon: "carbon:ibm-cloud-pak-manta-automated-data-lineage",
        component: () => import("@/pages/stat/device.vue"),
      },
    ],
  },
  {
    path: "/admin",
    name: "系统管理",
    icon: "clarity:administrator-line",
    component: () => import("@/pages/container"),
    children: [
      {
        path: "/admin/admin",
        name: "管理员列表",
        icon: "clarity:administrator-line",
        component: () => import("@/pages/admin/admin.vue"),
      },
      {
        path: "/admin/authority",
        name: "权限管理",
        icon: "icon-park-outline:permissions",
        component: () => import("@/pages/admin/authority.vue"),
      },
      {
        path: "/admin/version",
        name: "版本",
        icon: "icon-park-outline:file-conversion-one",
        component: () => import("@/pages/admin/version.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
// router.beforeEach(( to, from, next) => {
//   if(to.path === '/login' || to.path === '/') {
//     next()
//   } else if(!getCookie('tokenKey')) {
//     next('/login')
//   } else next()
// })
export default router;
