import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import http from "./utils/http.js";
import * as api from "./api/index.js";
import * as ElIcon from "@element-plus/icons-vue";
import "element-plus/theme-chalk/index.css";
import "@purge-icons/generated";

ElMessage.info = msg => ElMessage({
  type: "info",
  message: msg
});

ElMessage.warn = msg => ElMessage({
  type: "warning",
  message: msg
});

ElMessage.error = msg => ElMessage({
  type: "error",
  message: msg
});

ElMessage.success = msg => ElMessage({
  type: "success",
  message: msg
});

const app = createApp({});
http.requestHandler(config => {
  let isPro = process.env.NODE_ENV === "production";
  config.baseURL = isPro ? "http://47.108.233.177/" : "http://127.0.0.1:7001/";
}); // 挂载全局

app.config.globalProperties.$http = http;
app.config.globalProperties.$api = api;

for (const [key, component] of Object.entries(ElIcon)) {
  app.component(key, component);
}

createApp(App).use(router).mount("#app"); // import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import http from './utils/http.js'
// import api from './api/index.js'
// import * as ElIcon from '@element-plus/icons-vue'
// import '@purge-icons/generated'
// const app = createApp({})
// http.requestHandler((config) => {
// 	config.baseURL = 'http://127.0.0.1:7001/';
// 	console.log(config, '=======config======');
// });
// // 挂载全局
// app.config.globalProperties.$http = http;
// app.config.globalProperties.$api = api;
// for (const [key, component] of Object.entries(ElIcon)) {
//   app.component(key, component)
// }
// createApp(App).use(router).mount('#app')