
import http from '@/utils/http.js'

let api = {
	videoCategorySave: "/api/video/category/save",
	videoCategoryList: '/api/video/category/list',
  videoCategoryDelete: '/api/video/category/delete',
	videoSave: "/api/video/save",
	videoList: '/api/video/list',
  videoDelete: '/api/video/delete',
	spriteCategorySave: "/api/sprite/category/save",
	spriteCategoryList: '/api/sprite/category/list',
  spriteCategoryDelete: '/api/sprite/category/delete',
	spriteSave: "/api/sprite/save",
	spriteList: '/api/sprite/list',
  spriteDelete: '/api/sprite/delete',
	soundCategorySave: "/api/sound/category/save",
	soundCategoryList: '/api/sound/category/list',
  soundCategoryDelete: '/api/sound/category/delete',
	soundSave: "/api/sound/save",
	soundList: '/api/sound/list',
  soundDelete: '/api/sound/delete',
  userList: '/api/user/list',
  userMenberEdit: '/api/user/menberEdit',
  importUser: '/api/user/import',

}

let isPro = process.env.NODE_ENV === "production";
export const baseUrl = isPro ? "http://47.108.233.177" : "http://127.0.0.1:7001";
export const uploadUrl = isPro ? "http://47.108.233.177/api/upload" : "http://127.0.0.1:7001/api/upload";

export const videoCategorySaveFn = (data) => {
	return http.post(api.videoCategorySave, data);
}

export const videoCategoryListFn = (data) => {
	return http.post(api.videoCategoryList, data);
}

export const videoCategoryDeleteFn = (data) => {
	return http.post(api.videoCategoryDelete, data);
}

export const videoSaveFn = (data) => {
	return http.post(api.videoSave, data);
}

export const videoListFn = (data) => {
	return http.post(api.videoList, data);
}

export const videoDeleteFn = (data) => {
	return http.post(api.videoDelete, data);
}

export const spriteCategorySaveFn = (data) => {
	return http.post(api.spriteCategorySave, data);
}

export const spriteCategoryListFn = (data) => {
	return http.post(api.spriteCategoryList, data);
}

export const spriteCategoryDeleteFn = (data) => {
	return http.post(api.spriteCategoryDelete, data);
}

export const spriteSaveFn = (data) => {
	return http.post(api.spriteSave, data);
}

export const spriteListFn = (data) => {
	return http.post(api.spriteList, data);
}

export const spriteDeleteFn = (data) => {
	return http.post(api.spriteDelete, data);
}

export const soundCategorySaveFn = (data) => {
	return http.post(api.soundCategorySave, data);
}

export const soundCategoryListFn = (data) => {
	return http.post(api.soundCategoryList, data);
}

export const soundCategoryDeleteFn = (data) => {
	return http.post(api.soundCategoryDelete, data);
}

export const soundSaveFn = (data) => {
	return http.post(api.soundSave, data);
}

export const soundListFn = (data) => {
	return http.post(api.soundList, data);
}

export const soundDeleteFn = (data) => {
	return http.post(api.soundDelete, data);
}

export const userListFn = (data) => {
	return http.post(api.userList, data);
}

export const userMenberEditFn = (data) => {
	return http.post(api.userMenberEdit, data);
}

export const userImportFn = (data) => {
	return http.post(api.importUser, data);
}